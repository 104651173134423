import "./jquery-global";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import './App.scss';
import App from './App.svelte';

const app = new App({
	target: document.body
});

export default app;