<script>
  import { navigate } from "svelte-routing";

</script>
<style>
  footer {
    position: fixed;
    bottom: 1rem;
    width: 100%;
  }
  .col {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 4rem;
  }
  p {
    color: var(--iride-secondary);
    font-size: 1.25rem;
    margin: 0;
  }
  .link {
    color: var(--iride-highlight);
  }
  .link:hover {
    color: var(--iride-primary);
    cursor:pointer; 
  }
  a {
    color: var(--iride-highlight); 
  }
  a:hover {
    color: var(--iride-primary);
    text-decoration: none;
  }
  a:visited {
    color: var(--iride-secondary);
  }
</style>

<footer>
  <div class="row divider" />
  <div class="row align-items-center">
    <div class="col">
      <p class="link" on:click={_ => navigate("policy")}>Privacy and Cookie Policy</p>
      <p>For more information <a href="mailto:info@iride.io">info@iride.io</a></p>
    </div>
  </div>
</footer>

