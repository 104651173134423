<script>
  import { Router, Route } from "svelte-routing";
  import Footer from "./components/footer/Footer.svelte";
  // import Navbar from "./components/navbar/Navbar.svelte";

  import Home from "./components/Home.svelte";
  import Redirect from "./components/Redirect.svelte";
  import Policy from "./components/policy/Policy.svelte";

  export let url = "";
</script>

<style>
</style>

<main role="main" class="flexy h100">
  <!-- <Navbar /> -->

  <Router {url}>
    <Route path="/" component={Home} />
    <Route path="/policy" component={Policy}/>
    <Route component={Redirect} to="/" />
  </Router>

  <Footer />
</main>
